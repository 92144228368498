import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { baseColor } from "../utils/color";
import { Snackbar, Button } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ImageSearch from "@material-ui/icons/ImageSearch";
import FileCopy from "@material-ui/icons/FileCopy";
import Launch from "@material-ui/icons/Launch";
const Gallary = (props: any) => {
  const isMobile = !useMediaQuery("(min-width:1000px)");
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        display: isMobile ? "block:" : "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
      },
      title: {
        marginTop: "20px",
        fontSize: "20px",
      },
      imageContainer: {
        position: "relative",
        border: "solid 1px #60606F",
        margin: "10px",
        backgroundColor: "#fff",
        textAlign: "center",
      },
      child: {
        width: "250px",
        height: "250px",
        color: "#60606F",
      },
      bar: {
        zIndex: 1000,
        color: "#000",
        minHeight: "2em",
        backgroundColor: "#fff",
        position: "absolute",
        fontSize: "12px",
        top: "0px",
        left: "0px",
        width: "100%",
        display: "flex",
        lineHeight: "1.5em",
        justifyContent: "center",
        alignItems: "center",
        wordBreak: "break-all",
        cursor: "pointer",
      },
      actions: {
        backgroundColor: "#FFF",
        padding: "10px",
      },
      action: {
        marginLeft: "5px",
      },
    })
  );
  const { images = [] } = props;
  const classes = useStyles({});
  const [copyMsg, setCopyMsg] = useState("");
  const [hoverBarcode, setHoverBarcode] = useState("");

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={() => {
          setCopyMsg("");
        }}
        open={!!copyMsg}
        transitionDuration={0}
        autoHideDuration={1000}
        message={copyMsg}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => {
          setHoverBarcode("");
        }}
        open={!!hoverBarcode}
        transitionDuration={0}
        autoHideDuration={1000}
        message={hoverBarcode}
      />
      {images.map((img: any) => {
        const { src, brief, data } = img;
        const mabangUrl = `https://901061.private.mabangerp.com/index.php?mod=stock.modify&id=${data.id}`;
        return (
          <div
            className={classes.imageContainer}
            key={brief}
            data-src={src}
            style={{
              border: `solid 2px ${baseColor}`,
            }}
          >
            <img
              src={data.stockPicture}
              className={classes.child}
              alt={data.nameCN}
            />
            <div className={classes.actions}>
              <Button
                className={classes.action}
                startIcon={<Launch />}
                size="small"
                variant="outlined"
                color="primary"
                href={mabangUrl}
                target="_blank"
              >
                马帮
              </Button>
              <Button
                className={classes.action}
                startIcon={<ImageSearch />}
                size="small"
                variant="outlined"
                color="primary"
                href={data.stockPicture}
                target="_blank"
              >
                大图
              </Button>
              <Button
                className={classes.action}
                startIcon={<FileCopy />}
                size="small"
                variant="outlined"
                color="primary"
                onMouseOver={() => {
                  setHoverBarcode(`${data.stockSku}`);
                }}
              >
                <CopyToClipboard
                  text={data.stockSku}
                  onCopy={() => setCopyMsg(`${data.stockSku} 复制成功`)}
                >
                  <span>条码</span>
                </CopyToClipboard>
              </Button>
              <Button
                className={classes.action}
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (!data.stockSku) {
                    setCopyMsg(`请先获取stockSku`);
                  }
                  window.open(
                    `${
                      process.env.REACT_APP_SA_URL
                    }/product-management/search/quotations-list?stockSku=${encodeURIComponent(
                      data.stockSku
                    )}`,
                    "_blank"
                  );
                }}
              >
                自主询价
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Gallary;

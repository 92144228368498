declare global {
    interface Window {
        _env_: any;
    }
}
// let endpoint = 'https://pic.cszecom.com/api/';
let endpoint = `http://127.0.0.1:5000`;
if (window._env_ && window._env_.API_URL) {
    endpoint = window._env_.API_URL;
}
let tableName = 'fforder_img_search'
if (window._env_ && window._env_.DEFAULT_TABLE) {
    tableName = window._env_.DEFAULT_TABLE;
}

export const Train = `${endpoint}/img/load?table_name=${tableName}`;
export const Processing = `${endpoint}/progress?table_name=${tableName}`;
export const Count = `${endpoint}/img/count?table_name=${tableName}`;
export const ClearAll = `${endpoint}/img/drop?table_name=${tableName}`;
export const Search = `${endpoint}/img/search/v2?table_name=${tableName}`;
export const GetImageUrl = `${endpoint}/data?table_name=${tableName}`;

import React, { useState, useEffect, useContext, useRef } from "react";
import { queryContext } from "../contexts/QueryContext";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import { DropzoneArea } from "material-ui-dropzone";
import SeperatLine from "../components/SeperatLine";
import { baseColor } from "../utils/color";
import { delayRunFunc } from "../utils/Helper";

const Setting = (props: any) => {
  const isMobile = !useMediaQuery("(min-width:1000px)");
  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      setting: {
        display: "flex",
        flexDirection: "column",
        minWidth: isMobile ? "90%" : "300px",
        padding: "60px 20px",
        borderWidth: "1px",
        backgroundColor: "#1F2023",
        color: "#E4E4E6",
        overflowY: "auto",
      },
      header: {
        marginBottom: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      configHead: {
        marginBottom: "30px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      config: {
        fontSize: "24px",
        color: "#FAFAFA",
      },
      clear: {
        color: baseColor,
        fontSize: "18px",
        cursor: "pointer",
      },
      imageSet: {},
      counts: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "30px",
        color: "#FAFAFA",
      },
      currTotal: {
        fontSize: "12px",
      },
      setPath: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        marginBottom: "30px",
      },
      customInput: {
        margin: "0 20px 0 0 !important",
        color: "blue !important",
        width: isMobile ? "80%" : "auto",
      },
      customFab: {
        color: "#fff",
        backgroundColor: baseColor,
        width: "36px",
        height: "36px",
        "&:hover": {
          backgroundColor: baseColor,
        },
      },
      customDeleteFab: {
        position: "absolute",
        top: "5px",
        right: "5px",
        color: "#fff",
        backgroundColor: "#666769",
        width: "24px",
        height: "24px",
        minHeight: "0px",
        "&:hover": {
          backgroundColor: "#666769",
        },
      },
      customDelete: {
        color: "#A7A7AF",
        width: "18px",
        height: "18px",
      },
      customIcon: {
        color: "#fff",
        backgroundColor: baseColor,
        width: "20px",
        height: "20px",
      },
      customSlider: {
        color: baseColor,
        marginBottom: "30px",
      },
      thumb: {
        width: "16px",
        height: "16px",
      },
      track: {
        height: "4px",
        borderRadius: "10px",
      },
      upload: {},
      benchImage: {
        width: "300px",
        position: "relative",
        textAlign: "center",
      },
      dropzoneContainer: {
        backgroundColor: "transparent",
        borderRadius: "5px",
        border: "solid .5px #C8C8C8",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      dropzoneText: {
        fontSize: "14px",
        color: "#B3B4B5",
        marginBottom: "30px",
      },
      notchedOutline: {
        borderWidth: ".5px",
        borderColor: "#838385 !important",
      },
      formLabel: {
        color: "#fff",
      },
      controlLabel: {
        color: "#838385",
      },
      parseInput: {
        lineHeight: "40px",
        display: "block",
        padding: "0 10px",
        margin: "0",
        flex: "1",
        borderRadius: "5px",
        border: "none",
        outline: "none",
      },
      clearButton: {
        marginLeft: "10px",
      },
    });
  });
  const { process, train, count, search } = useContext(queryContext);
  const { setImages, loading, setLoading } = props;
  const classes = useStyles({});
  const [inputs, setInputs]: any = useState("");
  const [topK, setTopK]: any = useState(50);
  const [totalNum, setTotalNum]: any = useState(0);
  const [[current, total], setProcessedNum]: any = useState([0, 0]);
  const [image, setImage]: any = useState();

  const benchImage = useRef<any>(null);
  const setText = loading
    ? "Loading..."
    : totalNum
    ? `${totalNum} 张产品图片`
    : "无图片";

  const reader = new FileReader();
  reader.addEventListener(
    "load",
    function () {
      if (benchImage.current) {
        benchImage.current.src = reader.result;
      }
    },
    false
  );
  useEffect(() => {
    if (props?.imageUrl?.length > 0) {
      _search({ topK, imageUrl: props.imageUrl });
    }
  }, [props.imageUrl]);
  const _search = ({ topK, image, imageUrl }: any) => {
    setLoading(true);
    const fd = new FormData();
    fd.set("topk", topK);
    if (image) {
      fd.append("image", image);
    } else {
      if (imageUrl) {
        fd.append("image_url", imageUrl);
      }
    }

    search(fd)
      .then((res: any) => {
        const { status, data } = res || {};
        if (status === 200) {
          setImages(data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const uploadImg = (file: any) => {
    setImage(file);
    reader.readAsDataURL(file);
    _search({ topK, image: file });
  };

  const onInputChange = (e: any) => {
    const val = e.target.value;
    setInputs(val);
  };

  const onTopKChange = (e: any, val: any) => {
    setTopK(val);
    if (val && image) {
      delayRunFunc({ topK: val, image }, _search, 300);
    }
  };
  const _keepProcess = () => {
    process().then((res: any) => {
      const { data, status } = res;
      if (status === 200) {
        const [_current, _total] = data
          .split(",")
          .map((item: any) => Number.parseInt(item.split(":")[1]));
        setProcessedNum([_current, _total]);
        if (_current !== _total) {
          setTimeout(() => _keepProcess(), 1000);
        } else {
          setTimeout(() => {
            count().then((res: any) => {
              const { data, status } = res;
              if (status === 200) {
                setTotalNum(data);
                setLoading(false);
              }
            });
          }, 3000);
        }
      }
    });
  };
  const uploadImgPath = () => {
    train({ File: inputs }).then((res: any) => {
      if (res.status === 200) {
        setLoading(true);
        setTimeout(() => {
          setInputs("");
          _keepProcess();
        }, 1000);
      }
    });
  };

  useEffect(() => {
    count().then((res: any) => {
      const { data, status } = res || {};
      if (status === 200) {
        setTotalNum(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handlePaste(event: any) {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    if (event.clipboardData && clipboardData.files.length > 0) {
      const file = clipboardData.files[0];
      if (file.type.startsWith("image/")) {
        uploadImg(file);
      }
    }
  }
  function handleChange() {}
  return (
    <div className={classes.setting}>
      {/* <div className={classes.header}>
        <img src={Logo} width="150px" alt="logo" />
        <p>Image Search Demo</p>
      </div>
      <div className={classes.configHead}>
        <h4 className={classes.config}>Config</h4>
        <h4 className={classes.clear} onClick={clear}>
          CLEAR ALL
        </h4>
      </div> */}
      <div className={classes.imageSet}>
        <div className={classes.counts}>
          <p style={{ color: loading ? baseColor : "#fff" }}>{setText}</p>
          <h3 className={classes.currTotal}>{`${current}/${total}`}</h3>
        </div>
        <div className={classes.setPath} style={{ display: "none" }}>
          <TextField
            classes={{ root: classes.customInput }}
            label=""
            variant="outlined"
            value={inputs}
            onChange={onInputChange}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.controlLabel,
                focused: classes.controlLabel,
              },
            }}
            margin="normal"
            InputProps={{
              style: {
                textAlign: "left",
                width: isMobile ? "100%" : "340px",
                height: "40px",
              },
              classes: {
                notchedOutline: classes.notchedOutline,
                root: classes.formLabel,
              },
              placeholder: "path/to/your/images",
            }}
          />
          <Fab
            classes={{
              root: classes.customFab,
              focusVisible: classes.customFab,
            }}
          >
            <AddIcon
              onClick={uploadImgPath}
              classes={{ root: classes.customIcon }}
            />
          </Fab>
        </div>
        <div style={{ display: "none" }}>
          <SeperatLine
            title={`TOP K(1－100)`}
            style={{ marginBottom: "20px" }}
          />
          <div className={classes.counts}>
            <p>{`show top ${topK} results`}</p>
          </div>
          <Slider
            min={1}
            max={100}
            value={topK}
            onChange={onTopKChange}
            classes={{
              root: classes.customSlider,
              track: classes.track,
              rail: classes.track,
              thumb: classes.thumb,
            }}
          />
        </div>
      </div>
      <div className={classes.upload} onPaste={handlePaste}>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <input
            className={classes.parseInput}
            type="text"
            placeholder="按下 Ctrl+V 粘贴截图上传"
            value={image?.name || ""}
            onChange={handleChange}
          />
          <Button
            className={classes.clearButton}
            color="primary"
            variant="contained"
            onClick={() => {
              setImage();
              setImages([]);
            }}
          >
            清除
          </Button>
        </div>
        <div>
          {image ? (
            <div className={classes.benchImage}>
              <img
                ref={benchImage}
                className={classes.benchImage}
                src={image}
                alt="..."
              />
            </div>
          ) : (
            <div>
              <DropzoneArea
                acceptedFiles={["image/*"]}
                filesLimit={1}
                dropzoneText={`点击此处选择文件上传`}
                onDrop={uploadImg}
                dropzoneClass={classes.dropzoneContainer}
                showPreviewsInDropzone={false}
                dropzoneParagraphClass={classes.dropzoneText}
                // maxFileSize={} bit
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Setting;
